///silinecekler
import superior from "../assets/images/1207/galleries/superiortwin/61920684d408f.jpeg";
import superior2 from "../assets/images/1207/galleries/superiortwin/61920684e03df.jpeg";
import superior3 from "../assets/images/1207/galleries/superiortwin/61920684eb943.jpeg";

import superiord from "../assets/images/1207/galleries/superriordouble/61920682b3a8c.jpeg";
import superiord2 from "../assets/images/1207/galleries/superriordouble/61920682c79a1.jpeg";
import superiord3 from "../assets/images/1207/galleries/superriordouble/61920682df1d0.jpeg";
import superiord4 from "../assets/images/1207/galleries/superriordouble/61920682f3bd3.jpeg";
import superiord5 from "../assets/images/1207/galleries/superriordouble/619206828b308.jpeg";
import superiord6 from "../assets/images/1207/galleries/superriordouble/619206829ff97.jpeg";
import superiord7 from "../assets/images/1207/galleries/superriordouble/619206833d461.jpeg";
import superiord8 from "../assets/images/1207/galleries/superriordouble/619206832992e.jpeg";
import superiord9 from "../assets/images/1207/galleries/superriordouble/619206831574f.jpeg";
import superiord10 from "../assets/images/1207/supsngn.jpg";

import kingsuit from "../assets/images/1207/galleries/kingsuit/61920683d1098.jpeg";
import kingsuit1 from "../assets/images/1207/galleries/kingsuit/61920683e8c40.jpeg";
import kingsuit2 from "../assets/images/1207/galleries/kingsuit/61920683e8c40-775x280.jpeg";
import kingsuit3 from "../assets/images/1207/galleries/kingsuit/619206837ccb8.jpeg";
import kingsuit4 from "../assets/images/1207/galleries/kingsuit/6192068367f5a.jpeg";
import kingsuit5 from "../assets/images/1207/galleries/kingsuit/6192068391af9.jpeg";
import kingsuit6 from "../assets/images/1207/galleries/kingsuit/619206835181e.jpeg";
import kingsuit7 from "../assets/images/1207/galleries/kingsuit/6192068422037.jpeg";
import kingsuit8 from "../assets/images/1207/galleries/kingsuit/61920683a6909.jpeg";
import kingsuit9 from "../assets/images/1207/galleries/kingsuit/61920683bb488.jpeg";

import eco from "../assets/images/1207/galleries/economydouble/6192068584381.jpeg";
import eco1 from "../assets/images/1207/galleries/economydouble/6192068598790.jpeg";
import eco2 from "../assets/images/1207/galleries/economydouble/61920685b4494.jpeg";
import eco3 from "../assets/images/1207/galleries/economydouble/61920685c88c8.jpeg";

import j from "../assets/images/1207/galleries/juniorsuite/619206850a4b9.jpeg";
import j2 from "../assets/images/1207/galleries/juniorsuite/619206851ed49.jpeg";
import j3 from "../assets/images/1207/galleries/juniorsuite/6192068532f6c.jpeg";
import j4 from "../assets/images/1207/galleries/juniorsuite/61920685471db.jpeg";

import d from "../assets/images/1207/handicapped/h1.png";
import d2 from "../assets/images/1207/handicapped/h2.png";
import d3 from "../assets/images/1207/handicapped/h3.png";

import trp1 from "../assets/images/1207/suptrpln/145120779.jpg";
import trp2 from "../assets/images/1207/suptrpln/506534142.jpg";
import trp3 from "../assets/images/1207/suptrpln/506534272.jpg";
import trp4 from "../assets/images/1207/suptrpln/506534286.jpg";
import trp5 from "../assets/images/1207/suptrpln/54284958.jpg";

import sng1 from "../assets/images/1207/supsng/54284958.jpg";
import sng2 from "../assets/images/1207/supsng/55831859.jpg";
import sng3 from "../assets/images/1207/supsng/55831871.jpg";
import sng4 from "../assets/images/1207/supsng/55831875.jpg";
import sng5 from "../assets/images/1207/supsng/55832026.jpg";
import sng6 from "../assets/images/1207/supsng/55832750.jpg";
import sng7 from "../assets/images/1207/supsng/55832754.jpg";

import fam1 from "../assets/images/1207/fam/466007461.jpg";
import fam2 from "../assets/images/1207/fam/466007483.jpg";
import fam3 from "../assets/images/1207/fam/466007507.jpg";
import fam4 from "../assets/images/1207/fam/466007528.jpg";
import fam5 from "../assets/images/1207/fam/506531883.jpg";
import fam6 from "../assets/images/1207/fam/506534142.jpg";

import tw1 from "../assets/images/1207/twin/tw1.png";
import tw2 from "../assets/images/1207/twin/tw2.png";
import tw3 from "../assets/images/1207/twin/tw3.png";

import alzerstd1 from "../assets/images/Alzer/DSC04093.jpg";
import alzerstd2 from "../assets/images/Alzer/DSC04086.jpg";
import alzerstd3 from "../assets/images/Alzer/DSC04080.jpg";

import alzerstdtw1 from "../assets/images/Alzer/DSC04130.jpg"

import alzerpasasuit1 from "../assets/images/Alzer/DSC04145.jpg"
import alzerpasasuit2 from "../assets/images/Alzer/DSC04169.jpg"
import alzerpasasuit3 from "../assets/images/Alzer/DSC04400.jpg"
import alzerpasasuit4 from "../assets/images/Alzer/Pa+şa.jpg"

import family1 from "../assets/images/Alzer/DSC04177.jpg"
import family2 from "../assets/images/Alzer/DSC04072.jpg"
import family3 from "../assets/images/Alzer/Family 1.jpg"

import stddouble1 from "../assets/images/Alzer/DSC04107.jpg"
import stddouble2 from "../assets/images/Alzer/DSC04119.jpg"

export const popularsData = [
  {
    id: 0,
    title: "Superior Single",
    images: [sng2, sng1, sng3, sng4, sng5, sng6, sng7],
    location: "Antalya Kaleiçi",
    description:
      "Üstün İkiz Oda Bu İkiz Oda, Klima, Elektrikli Su Isıtıcısı ve Minibar ile Donatılmıştır. Bu Oda Tipinde Ek Yatak Bulunmamaktadır.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "5 days - 4 nights",
    price: 101,
    afterDiscount: 92,
    rating: 2,
    reviews: "Oda",
    size: 11,
    minPerson: 2,
    view: "Şehir Manzaralı",
  },
  {
    id: 1,
    title: "Superior Double",
    images: [
      superiord10,
      superiord,
      superiord2,
      superiord3,
      superiord4,
      superiord5,
      superiord6,
      superiord8,
      superiord7,
      superiord9,
    ],
    location: "Antalya Kaleiçi",
    description:
      "Bu Süit Odada Balkon, Elektrikli Su Isıtıcısı ve Uydu TV Bulunmaktadır.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 1,
    reviews: "Oda",
    size: 16,
    minPerson: 2,
    view: "Deniz Manzaralı",
  },
  {
    id: 6,
    title: "Superior Triple",
    images: [trp1, trp2, trp3, trp4, trp5],
    location: "Antalya Kaleiçi",
    description:
      "Bu Süit Odada Balkon, Elektrikli Su Isıtıcısı ve Uydu TV Bulunmaktadır.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 1,
    reviews: "Oda",
    size: 20,
    minPerson: 2,
    view: "Deniz Manzaralı",
  },
  {
    id: 8,
    title: "Superior Twin",
    images: [tw1, tw2, tw3],
    location: "Antalya Kaleiçi",
    description:
      "Bu Süit Odada Balkon, Elektrikli Su Isıtıcısı ve Uydu TV Bulunmaktadır.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 1,
    reviews: "Oda",
    size: 18,
    minPerson: 2,
    view: "Deniz Manzaralı",
  },
  {
    id: 2,
    title: "Deluxe Suite",
    images: [
      kingsuit,
      kingsuit1,
      kingsuit2,
      kingsuit3,
      kingsuit4,
      kingsuit5,
      kingsuit6,
      kingsuit7,
      kingsuit8,
      kingsuit9,
    ],
    location: "Antalya Kaleiçi",
    description:
      "Geniş ve lüks Deluxe Suite odamız, ayrı oturma alanı ve muhteşem manzarasıyla unutulmaz bir konaklama deneyimi sunuyor.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 1,
    reviews: "Oda",
    size: 26,
    minPerson: 2,
    view: "Panoramik Deniz Manzaralı",
  },
  {
    id: 3,
    title: "Economy Double",
    images: [superior, superior3, eco, eco1, eco2, eco3],
    location: "Antalya Kaleiçi",
    description:
      "Geniş ve lüks Deluxe Suite odamız, ayrı oturma alanı ve muhteşem manzarasıyla unutulmaz bir konaklama deneyimi sunuyor.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 1,
    reviews: "Oda",
    size: 10,
    minPerson: 2,
    view: "Panoramik Deniz Manzaralı",
  },

  {
    id: 4,
    title: "Junior Suite",
    images: [j, j2, j3, j4],
    location: "Antalya Kaleiçi",
    description:
      "Geniş ve lüks Deluxe Suite odamız, ayrı oturma alanı ve muhteşem manzarasıyla unutulmaz bir konaklama deneyimi sunuyor.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 1,
    reviews: "Oda",
    size: 20,
    minPerson: 2,
    view: "Panoramik Deniz Manzaralı",
  },
  {
    id: 5,
    title: "Handicapped",
    images: [d, d2, d3],
    location: "Antalya Kaleiçi",
    description:
      "Geniş ve lüks Deluxe Suite odamız, ayrı oturma alanı ve muhteşem manzarasıyla unutulmaz bir konaklama deneyimi sunuyor.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 1,
    reviews: "Oda",
    size: 45,
    minPerson: 2,
    view: "Panoramik Deniz Manzaralı",
  },
  {
    id: 7,
    title: "Family Room",
    images: [fam5, fam1, fam2, fam3, fam4, fam6],
    location: "Antalya Kaleiçi",
    description:
      "Geniş ve lüks Deluxe Suite odamız, ayrı oturma alanı ve muhteşem manzarasıyla unutulmaz bir konaklama deneyimi sunuyor.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "2 days - 1 nights",
    price: 87,
    afterDiscount: 82,
    rating: 1,
    reviews: "Oda",
    size: 24,
    minPerson: 2,
    view: "Panoramik Deniz Manzaralı",
  },
];
export const popularsData2 = [
  {
    id: 10,
    title: "Standart Oda",
    images: [alzerstd1, alzerstd2, alzerstd3],
    location: "Antalya Kaleiçi",
    description:
      "Üstün Standart Oda Bu Standart Oda, Klima, Elektrikli Su Isıtıcısı ve Minibar ile Donatılmıştır. Bu Oda Tipinde Ek Yatak Bulunmamaktadır.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "5 days - 4 nights",
    price: 101,
    afterDiscount: 92,
    rating: 2,
    reviews: "Oda",
    size: 11,
    minPerson: 2,
    view: "Şehir Manzaralı",
  },
  {
    id: 11,
    title: "Standart Oda Twin",
    images: [alzerstdtw1, alzerstd2, alzerstd3],
    location: "Antalya Kaleiçi",
    description:
      "Bu Twin Oda, Klima, Elektrikli Su Isıtıcısı ve Minibar ile Donatılmıştır.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "5 days - 4 nights",
    price: 101,
    afterDiscount: 92,
    rating: 2,
    reviews: "Oda",
    size: 11,
    minPerson: 2,
    view: "Şehir Manzaralı",
  },
  {
    id: 12,
    title: "Standart Double Oda",
    images: [stddouble1, stddouble2],
    location: "Antalya Kaleiçi",
    description:
      "Bu Twin Oda, Klima, Elektrikli Su Isıtıcısı ve Minibar ile Donatılmıştır.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "5 days - 4 nights",
    price: 101,
    afterDiscount: 92,
    rating: 2,
    reviews: "Oda",
    size: 11,
    minPerson: 2,
    view: "Şehir Manzaralı",
  },
  {
    id: 13,
    title: "Paşa Suite",
    images: [alzerpasasuit1, alzerpasasuit3,alzerpasasuit4,alzerpasasuit2],
    location: "Antalya Kaleiçi",
    description:
    "Geniş ve lüks Deluxe Paşa Suite odamız, ayrı oturma alanı ve muhteşem manzarasıyla unutulmaz bir konaklama deneyimi sunuyor. King Size Bed + 2 Sofa Bed",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "5 days - 4 nights",
    price: 101,
    afterDiscount: 92,
    rating: 2,
    reviews: "Oda",
    size: 11,
    minPerson: 2,
    view: "Şehir Manzaralı",
  },
  {
    id: 14,
    title: "Hippodrome",
    images: [family2,family3,family1],
    location: "Antalya Kaleiçi",
    description:
    "Bu Odamızda Klima, Elektrikli Su Isıtıcısı ve Minibar ile Donatılmıştır. 1 King Size Bed + 1 Extra Bed Vardır.",
    amenities: [
      { icon: "wifi", name: "Yüksek Hızlı Wi-Fi" },
      { icon: "balcony", name: "Özel Balkon" },
      { icon: "sea-view", name: "Deniz Manzarası" },
      { icon: "aircon", name: "Klima" },
      { icon: "safe", name: "Güvenli Kasa" },
      { icon: "breakfast", name: "Kahvaltı" },
      { icon: "security", name: "Akıllı Güvenlik" },
      { icon: "maid", name: "Kat Hizmetleri" },
      { icon: "shower", name: "Duş" },
      { icon: "support", name: "24/7 Çevrimiçi Destek" },
      { icon: "fruit", name: "Meyve İkramı" },
      { icon: "single", name: "Tekli Yatak" },
      { icon: "team", name: "Uzman Ekip" },
      { icon: "market", name: "Yakında market" },
      { icon: "towel", name: "Havlu" },
    ],
    category: ["Lüx"],
    days: "5 days - 4 nights",
    price: 101,
    afterDiscount: 92,
    rating: 2,
    reviews: "Oda",
    size: 11,
    minPerson: 2,
    view: "Şehir Manzaralı",
  },
];
