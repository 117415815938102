import React, { useState } from "react";
import Breadcrumbs from "../../../componentsAlzer/Breadcrumbs/Breadcrumbs";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import { Play } from "lucide-react";
import "../About/about.css";
import img from "../../../assets/images/Alzer/bg.jpg";
import hotelvideo from '../../../assets/images/Alzer/istanbul.mp4';

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Card className="feature-card text-dark mb-4 h-100">
      <Card.Body className="d-flex flex-column">
        <div className="Alzer-color mb-3 fs-2">
          <i className={icon}></i>
        </div>
        <Card.Title className="fs-4 mb-3">{title}</Card.Title>
        <Card.Text className="flex-grow-1">{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export const About = () => {
  const [showVideo, setShowVideo] = useState(false);

  const features = [
    {
      icon: "bi bi-stars",
      title: "Yüksek Takdir",
      description:
        "Otelimiz, yüksek kaliteli ve özenli performansıyla misafirlerimizin takdirini kazanmıştır.",
    },
    {
      icon: "bi bi-hourglass",
      title: "Huzurlu Saatler",
      description:
        "Sessiz ve konforlu odalarımız, sakin atmosfer ve profesyonel hizmet anlayışımızla huzurlu bir konaklama.",
    },
    {
      icon: "bi bi-geo-alt",
      title: "İyi Lokasyon",
      description:
        "Şehrin önemli noktalarına ve turistik mekanlara yakın stratejik konumumuz.",
    },
    {
      icon: "bi bi-x-lg",
      title: "Ücretsiz İptal",
      description:
        "Belirli bir süre öncesinde yapılan iptallerde herhangi bir ücret talep etmiyoruz.",
    },
    {
      icon: "bi bi-credit-card-2-front",
      title: "Ödeme Esnekliği",
      description:
        "Çeşitli ödeme seçenekleri ve erken rezervasyon indirimleri sunuyoruz.",
    },
    {
      icon: "bi bi-percent",
      title: "Özel Teklifler",
      description:
        "Misafirlerimize özel kampanyalar ve avantajlı konaklama seçenekleri sağlıyoruz.",
    },
  ];

  return (
    <>
      <Breadcrumbs title="Hakkımızda" pagename="Hakkımızda" />
      <section className="py-5">
        <Container>
          <Row className="mb-5 align-items-center">
            <Col lg={7} className="mb-4 mb-lg-0">
              <div className="about-image-content text-dark">
                <h2
                  className="mb-4 display-5 fw-bold"
                  style={{ fontFamily: "Playfair Display, serif" }}
                >
                  Alzer Hotel Special Class
                </h2>
                <p
                  className="lead"
                  style={{ fontFamily: "Roboto, sans-serif" }}
                >
                  Alzer Hotel, Tarihi Hipodrom'un tek otelidir. Tüm turistik mekanlara çok yakındır. Alzer Otel'de konaklarsanız tüm tarihi yerlere yürüyerek ulaşabilirsiniz. En önemli anıtlar ve müzeler otelin her iki yanında yer almaktadır. Ayrıca tramvay, otobüs, metro tren vb. toplu taşıma araçlarını kullanarak diğer bölgelere ulaşım oldukça kolaydır. <br></br><br></br>
                  Alzer Hotel'in önündeki küçük kafe olan Alzer Garden Cafe, misafirlerine pek çok imkan sunuyor. Birçok yiyecek ve içecek seçeneği var. Orada oturmak, akşam yemeği yemek ya da akşam içki içmek harika. <br></br> <br></br>
                  Her sabah bir tarafta Sultanahmet Camii ve Ayasofya'nın, diğer tarafta ise Marmara Denizi'nin muhteşem manzarasını seyrederken kahvaltınızın tadını çıkarabilirsiniz. Kahvaltı yerel şefimiz tarafından taze olarak hazırlanmaktadır. Kahvaltının ardından şehir turlarına hazır olduğunuzu hissedeceksiniz.<br></br><br></br>
                  Alzer Hotel'de farklı dekorasyon ve renklere sahip farklı tipte odalar bulunmaktadır. Odaların tamamı aynı olanaklara sahiptir, tek fark odaların boyutlarıdır. Tüm odalar konforludur ve dışarıya bakan pencerelere sahiptir. <br></br><br></br>

                </p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="position-relative video-wrapper">
                <img
                  src={img}
                  alt="Hotel 1207"
                  className="img-fluid rounded shadow w-100"
                />
                <div className="play-button-Alzer" onClick={() => setShowVideo(true)}>
                  <Play size={40} className="play-icon" />
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col md={12}>
              <h2 className="text-center mb-5 section-title-Alzer">
                Temel Özellikler
              </h2>
            </Col>
            {features.map((feature, index) => (
              <Col md={6} lg={4} key={index} className="mb-4">
                <FeatureCard {...feature} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <Modal
        show={showVideo}
        onHide={() => setShowVideo(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Alzer Hotel Special Class Tanıtım</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <video 
            src={hotelvideo}
            width="100%"
            height="500px"
            controls
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default About;