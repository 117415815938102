import React, { Suspense, lazy } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LoadingSpinner from "../../../components/Common/LoadingSpinner/LoadingSpinner";
import "./Home.css";
import { popularsData2 } from "../../../utils/data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Lazy loaded components
const Banner = lazy(() =>
  import("../../../componentsAlzer/Common/Banner/Banner")
);
const ReservationFilter = lazy(() =>
  import("../../../componentsAlzer/Common/ReservationFilter/ReservationFilter")
);
const Features = lazy(() =>
  import("../../../componentsAlzer/Features/Features")
);
const Rooms = lazy(() => import("../../../components/Common/Rooms/Rooms"));
const Gallery = lazy(() => import("../../../componentsAlzer/Gallery/Gallery"));
const PopularCard = lazy(() =>
  import("../../../componentsAlzer/Cards/PopularCard")
);

// Intersection Observer ile lazy loading kontrolü için custom hook
const useIntersectionObserver = (callback, options = {}) => {
  const [ref, setRef] = React.useState(null);

  React.useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          callback();
        }
      }, options);

      observer.observe(ref);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref, callback, options]);

  return setRef;
};

const Home = () => {
  const [showFeatures, setShowFeatures] = React.useState(false);
  const [showRooms, setShowRooms] = React.useState(false);
  const [showGallery, setShowGallery] = React.useState(false);
  const [showPopular, setShowPopular] = React.useState(false);

  // Intersection Observer referansları
  const featuresRef = useIntersectionObserver(() => setShowFeatures(true));
  const roomsRef = useIntersectionObserver(() => setShowRooms(true));
  const galleryRef = useIntersectionObserver(() => setShowGallery(true));
  const popularRef = useIntersectionObserver(() => setShowPopular(true));

  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <Banner />
      </Suspense>

      <div className="reservation-filter-container">
        <Suspense fallback={<LoadingSpinner />}>
          <ReservationFilter />
        </Suspense>
      </div>

      <div ref={featuresRef}>
        {showFeatures && (
          <Suspense fallback={<LoadingSpinner />}>
            <Features />
          </Suspense>
        )}
      </div>

      {/* <div ref={roomsRef}>
        {showRooms && (
          <Suspense fallback={<LoadingSpinner />}>
            <Rooms />
          </Suspense>
        )}
      </div>
*/}
      <section className="popular py-5" ref={popularRef}>
        {showPopular && (
          <Suspense fallback={<LoadingSpinner />}>
            <Container>
              <Row>
                <Col md="12">
                  <div className="main_heading">
                    <h1>Odalar</h1>
                  </div>
                </Col>
              </Row>
              <Row>
                {popularsData2.map((val, inx) => (
                  <Col md={3} sm={6} xs={12} className="mb-5" key={inx}>
                    <PopularCard val={val} />
                  </Col>
                ))}
              </Row>
            </Container>
          </Suspense>
        )}
      </section>

      <section className="call_us">
        <Container>
          <Row className="align-items-center">
            <Col md="8">
              <h5 className="title">TARİHİN KALBİNDE</h5>
              <h2 className="heading">
                SULTANAHMET'İN EŞSIZ MANZARASIYLA ALZER HOTEL'DE AYRICALIKLI
                KONAKLAMA
              </h2>
              <p className="text">
                İstanbul'un tarihi yarımadasının kalbinde, Sultanahmet Camii ve
                Ayasofya'nın görkemli siluetleri arasında yer alan Alzer Hotel,
                size benzersiz bir İstanbul deneyimi sunuyor. Osmanlı ve Bizans
                mimarisinin eşsiz örneklerine yürüme mesafesindeki konumumuz,
                geleneksel Türk misafirperverliğiyle harmanlanmış hizmet
                anlayışımız ve konforlu odalarımızla, İstanbul'un tarihini
                yaşayarak keşfetmeniz için mükemmel bir üs. Tarihin, kültürün ve
                modern konforun buluştuğu Alzer Hotel'de, unutulmaz bir İstanbul
                deneyimine hazır olun!
              </p>
            </Col>
            <Col md="4" className="text-center mt-3 mt-md-0">
              <a
                href="tel:+90 212 516 6262"
                className="secondary_btn bounce"
                rel="no"
              >
                {" "}
                İletişime Geç !
              </a>
            </Col>
          </Row>
        </Container>
        <div className="overlay"></div>
      </section>

      <section className="gallery" ref={galleryRef}>
        {showGallery && (
          <Suspense fallback={<LoadingSpinner />}>
            <Container>
              <Row>
                <Col md="12">
                  <div className="main_heading">
                    <h1>Fotoğraf Galerisi</h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Gallery isHomePage={true} />
                </Col>
              </Row>
            </Container>
          </Suspense>
        )}
      </section>
    </>
  );
};

export default Home;
