import React, { useState, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./gallery.css";

// Dynamically import all images from the Alzer folder
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/images/Alzer', false, /\.(jpg|jpeg|png|gif)$/));

const Gallery = ({ isHomePage = false }) => {
  const [index, setIndex] = useState(-1);
  const navigate = useNavigate();

  useEffect(() => {
    //console.log(images);
  }, []);

  const displayedImages = isHomePage ? images.slice(0, 10) : images;

  return (
    <>
      <Container>
        <Row className="g-3">
          {displayedImages.map((src, i) => (
            <Col key={i} xs={6} md={4} lg={3}>
              <img
                src={src}
                alt={`Hotel Photo ${i + 1}`}
                onClick={() => setIndex(i)}
                style={{ cursor: "pointer", width: "100%" }}
              />
            </Col>
          ))}
        </Row>
        {isHomePage && images.length > 10 && (
          <Row className="mt-4">
            <Col className="text-center">
              <a className="primaryBtn hoverbtn" onClick={() => navigate("/sultanahmethotel/photogallery")}>
                Tüm Resimleri Gör
              </a>
            </Col>
          </Row>
        )}
        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={displayedImages.map((src) => ({ src }))}
          plugins={[Zoom]}
        />
      </Container>
    </>
  );
};

export default Gallery;