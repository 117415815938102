import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/Common/ScrolToTop/ScrolToTop';
import Header from './components/Common/Header/Header';
import HeaderAlzer from './componentsAlzer/Common/Header/Header.jsx';
import FooterAlzer from './componentsAlzer/Common/Footer/Footer';
import Footer from './components/Common/Footer/Footer';
import Home from './pages/Home/Home';
import RoomType from './pages/RoomType/RoomType';
import RoomTypeAlzer from './pages/AlzerHotel/RoomType/RoomType.jsx';
import RoomTypeDetailAlzer from './pages/AlzerHotel/RoomType/RoomTypeDetail.jsx';
import PhotoGallery from './pages/PhotoGallery/PhotoGallery';
import Contact from './pages/Contact/Contact';
import ContactAlzer from './pages/AlzerHotel/Contact/Contact.jsx';
import About from './pages/About/About';
import AlzerHotelAbout from './pages/AlzerHotel/About/About.jsx';
import AlzerHotelGallery from './pages/AlzerHotel/PhotoGallery/PhotoGallery.jsx';
import RoomTypeDetail from './pages/RoomType/RoomTypeDetail';
import OnlineRes from './pages/OnlineReservation/OnlineRes';
import OnlineResAlzer from './pages/AlzerHotel/OnlineReservation/OnlineRes.jsx';
import NotFound from './components/NotFound/NotFound';
import AlzerHotelHome from './pages/AlzerHotel/Home/AlzerHome.jsx';

function App() {
  const location = useLocation(); // Geçerli URL'yi almak için kullanılır

  // `/alzerhotel/home` yolunu kontrol et
  const isAlzerHotel = location.pathname.startsWith('/sultanahmethotel');

  return (
    <>
      <div className="main-content">
        {/* Eğer URL '/alzerhotel/home' ile başlıyorsa, Alzer Hotel için özel Header'ı render et */}
        {isAlzerHotel ? <HeaderAlzer /> : <Header />}
        
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about-us' element={<About />} />
          <Route path='contact-us' element={<Contact />} />
          <Route path='/sultanahmethotel/contact-us' element={<ContactAlzer />} />
          <Route path='photogallery' element={<PhotoGallery />} />
          <Route path='roomtype' element={<RoomType />} />
          <Route path='/sultanahmethotel/RoomTypeAlzer' element={<RoomTypeAlzer />} />
          <Route path='/roomtype-detail/:title' element={<RoomTypeDetail />} />
          <Route path='/sultanahmethotel/roomtype-detail-Alzer/:title' element={<RoomTypeDetailAlzer />} />
          <Route path='OnlineRes' element={<OnlineRes />} />
          <Route path='/sultanahmethotel/OnlineRes' element={<OnlineResAlzer />} />
          <Route path="/sultanahmethotel/home" element={<AlzerHotelHome />} />
          <Route path="/sultanahmethotel/about-us" element={<AlzerHotelAbout />} />
          <Route path="/sultanahmethotel/photogallery" element={<AlzerHotelGallery />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      {/* Eğer URL '/alzerhotel/home' ile başlıyorsa, Alzer Hotel için özel Header'ı render et */}
      {isAlzerHotel ? <FooterAlzer /> : <Footer />}
    </>
  );
}

export default App;
