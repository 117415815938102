import React, { useState, useMemo } from "react";
import Breadcrumbs from "../../../componentsAlzer/Breadcrumbs/Breadcrumbs";
import "../RoomType/roomtype.css";
import { popularsData2 } from "../../../utils/data";
import { useParams, NavLink } from "react-router-dom";
import { Container, Row, Col, Nav, Tab, Card } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { FaLeaf } from "react-icons/fa";
import {
  FaWifi,
  FaTv,
  FaSnowflake,
  FaCoffee,
  FaExpandArrowsAlt,
  FaMountain,
  FaMapMarkerAlt,
  FaSubway,
  FaPlane,
  FaBath,
  FaGlassMartini,
  FaBell,
  FaHome,
  FaDoorOpen,
  FaStar,
  FaLock,
  FaUtensils,
  FaShieldAlt,
  FaBroom,
  FaShower,
  FaHeadset,
  FaAppleAlt,
  FaBed,
  FaUsers,
  FaStore,
  FaTshirt,
  FaHandSparkles,
} from "react-icons/fa";

const amenityIcons = {
  wifi: <FaWifi />,
  tv: <FaTv />,
  "air-conditioner": <FaSnowflake />,
  aircon: <FaSnowflake />,
  coffee: <FaCoffee />,
  bathtub: <FaBath />,
  minibar: <FaGlassMartini />,
  "room-service": <FaBell />,
  kitchen: <FaHome />,
  balcony: <FaDoorOpen />,
  "sea-view": <FaMountain />,
  safe: <FaLock />,
  breakfast: <FaUtensils />,
  security: <FaShieldAlt />,
  maid: <FaBroom />,
  shower: <FaShower />,
  support: <FaHeadset />,
  fruit: <FaAppleAlt />,
  single: <FaBed />,
  team: <FaUsers />,
  market: <FaStore />,
  towel: <FaHandSparkles />,
  default: <FaStar />,
};

export const RoomTypeDetail = () => {
  const { title } = useParams();
  const [activeTab, setActiveTab] = useState("overview");

  const roomData = useMemo(() => {
    const decodedTitle = decodeURIComponent(title);
    return popularsData2.find((room) => room.title === decodedTitle);
  }, [title]);

  const galleryItems = useMemo(() => {
    if (!roomData) return [];
    return roomData.images.map((image) => ({
      original: image,
      thumbnail: image,
    }));
  }, [roomData]);

  if (!roomData) {
    return <div>Oda bulunamadı</div>;
  }

  const renderFeature = (icon, text) => (
    <li key={text} className="feature-item-alzer">
      {icon}
      <span>{text}</span>
    </li>
  );

  const renderAmenity = (amenity) => {
    let icon, name;

    if (typeof amenity === "string") {
      icon = amenityIcons[amenity] || amenityIcons.default;
      name = amenity;
    } else if (typeof amenity === "object" && amenity !== null) {
      icon = amenityIcons[amenity.icon] || amenityIcons.default;
      name = amenity.name;
    } else {
      return null;
    }

    return (
      <Col xs={6} md={4} key={name} className="mb-3">
        <Card className="h-100 amenity-card">
          <Card.Body className="d-flex align-items-center">
            <div className="amenity-icon-alzer mr-3">{icon}</div>
            <div className="amenity-name">{name}</div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <Breadcrumbs
        title={roomData.title}
        pagename="Odalar"
        childPageName={roomData.title}
      />
      <section className="room_details py-5">
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="fs-2 font-bold mb-4">{roomData.title}</h1>
              <ImageGallery
                items={galleryItems}
                additionalClass="alzer-gallery"
                showPlayButton={true}
                showFullscreenButton={true}
                showBullets={true}
                showThumbnails={true}
                showNav={true}
              />
              <div className="mt-4">
                <p>{roomData.description}</p>
              </div>
            </Col>
            <Col lg={12}>
              <Tab.Container
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
              >
                <Nav variant="tabs" className="mt-4 custom-tabs-alzer">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">Genel Bakış</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="amenities">Özellikler</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="location">Konum</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="mt-3">
                  <Tab.Pane eventKey="overview">
                    <Row>
                      <Col md={6}>
                        <div className="room-description">
                          <h3>Oda Açıklaması</h3>
                          <p>{roomData.description}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="room-features">
                          <h3>Oda Özellikleri</h3>
                          <ul className="feature-list">
                            {renderFeature(
                              <FaExpandArrowsAlt />,
                              `Boyut: ${roomData.size} m²`
                            )}
                            {renderFeature(
                              <FaBed />,
                              `Kategori: ${roomData.category.join(", ")}`
                            )}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="amenities">
                    <div className="room-amenities">
                      <div className="section-title-alzer">
                        <FaLeaf className="section-icon-alzer" />
                        <h3>Olanaklar</h3>
                        <div className="section-underline-alzer"></div>
                      </div>
                      <Row className="mt-4">
                        {roomData.amenities.map(renderAmenity)}
                      </Row>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="location">
                    <div className="room-location">
                      <h3>Konum Bilgileri</h3>
                      <Row>
                        <Col md={6}>
                          <p>
                            Otelimiz, şehrin en merkezi konumunda yer
                            almaktadır.
                          </p>
                          {/*     <ul className="location-list">
                                                        {renderFeature(<FaMapMarkerAlt />, "Şehir merkezine 5 dakika yürüme mesafesinde")}
                                                        {renderFeature(<FaSubway />, "En yakın metro istasyonuna 200 metre")}
                                                        {renderFeature(<FaPlane />, "Havalimanına 30 km uzaklıkta")}
                                                    </ul>
                                                    */}
                        </Col>
                        <Col md={6}>
                          <div className="google-map">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.900922303818!2d28.971827011723477!3d41.005541719473705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab99797d1e859%3A0x6cd94ae003c27675!2sAlzer%20Hotel%20Special%20Class!5e0!3m2!1sen!2str!4v1715164149888!5m2!1sen!2str"
                              width="100%"
                              height="300"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default RoomTypeDetail;
