import React from 'react'
import Breadcrumbs from '../../../componentsAlzer/Breadcrumbs/Breadcrumbs' ;
import { Container, Row, Col, Card, Form, FloatingLabel } from 'react-bootstrap';
import "../Contact/contact.css";
export const Contact = () => {
    return (

        <>
            <Breadcrumbs title="İletişim" pagename="İletişim" />
            <section ClassName="contact pt-5">
                <Container>
                  
                    <Row className="py-5">
                        <Col lg="4" md="6" className="mb-4 mb-lg-0">
                            <Card className="border-0 shadow rounded-3 mb-4 cardhover">
                                <Card.Body className="text-center">
                                    <div className="d-flex justify-content-center align-item-search my-2">
                                        <div className=" bg-info rounded-circle text-info shadow-sm bg-opacity-10 p-3 mb-2">
                                            <i className="bi bi-headset h3"></i>
                                        </div>
                                    </div>
                                    <Card.Title className="fw-bold h5">
                                    Telefon Numarası
                                    </Card.Title>
                                    <p className="mb-3 body-text">
                                        Bizi her zaman arayın.
                                    </p>
                                    <div className="d-block justify-content-between">
                                        <a type="button" className="btn btn-light me-2 btn-sm">
                                            <i className="bi bi-phone me-1"></i>
                                            +90 (212) 516 6262
                                        </a>
                                        {/*<a type="button" className="btn btn-light me-2 btn-sm">
                                            <i className="bi bi-telephone me-1"></i>
                                            +905465465466
                                        </a>
                                        */}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>


                        <Col lg="4" md="6" className="mb-4 mb-lg-0">
                            <Card className="border-0 shadow rounded-3 mb-4">
                                <Card.Body className="text-center">
                                    <div className="d-flex justify-content-center align-item-search my-2">
                                        <div className=" bg-danger rounded-circle text-danger shadow-sm bg-opacity-10 p-3 mb-2">
                                            <i className="bi bi-envelope h3"></i>
                                        </div>
                                    </div>
                                    <Card.Title className="fw-bold h5">
                                    E-Posta Adresi
                                    </Card.Title>
                                    <p className="mb-3 body-text">
                                        Bize Mail Atın.
                                        
                                    </p>
                                    <div className="d-block justify-content-between">
                                        <a type="button" className="btn btn-light me-2 btn-sm">
                                        {event => {
                      event.preventDefault();
                      window.open('https://www.google.com/', '_blank');
    }}
                                            <i className="bi bi-envelope me-2" ></i>
                                            alzer@alzerhotel.com
                                        </a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>


                        <Col lg="4" md="6" className="mb-4 mb-lg-0">
                            <Card className="border-0 shadow rounded-3 mb-4">
                                <Card.Body className="text-center">
                                    <div className="d-flex justify-content-center align-item-search my-2">
                                        <div className=" bg-warning rounded-circle text-warning shadow-sm bg-opacity-10 p-3 mb-2">
                                            <i className="bi bi-geo-alt h3"></i>
                                        </div>
                                    </div>
                                    <Card.Title className="fw-bold h5">
                                    Otel Adresi
                                    </Card.Title>
                                    <p className="mb-3 body-text">
                                        Bizi Ziyaret Edin
                                    </p>
                                    <div className="d-block justify-content-between">
                                        <a type="button" className="btn btn-light me-2 btn-sm">
                                          {/*
                                          <a href="https://www.instagram.com/kepturotel/?igsh=MWV6MnZndTRjbDVidQ%3D%3D" target="_blank" rel="noopener norefferer"> 
                                           </a>
                                          */ } 
                                          <a href="https://www.google.com/maps/dir//Barbaros,+Kocatepe+Sk.+No:13,+07100+Muratpa%C5%9Fa%2FAntalya/@36.8837128,30.6257155,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14c39006f08b826f:0x2c41ba04b07f9395!2m2!1d30.7081122!2d36.8837312?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                                          <i className="bi bi-geo-alt me-2"></i>
                                          At Meydani No: 20, Sultanahmet, Istanbul
                                          </a>
                                        </a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                     {/*<Row className="py-5 align-items-center">
                        <Col xl="6" md="6" className="d-none d-md-block">
                            <img src={image} alt="" className="img-fluid me-3" />
                        </Col>
                       <Col xl="6" md="6">
                            <Card className="bg-light p-4 border-0 shadow-sm">
                                <div className="form-box">
                                    <h1 className="h3 font-bold mb-4">Bize Mesaj Gönder</h1>
                                    <Form>
                                        <Row>
                                            <Col md="6">
                                                <FloatingLabel controlId="name" label="İsim" className="mb-4">
                                                    <Form.Control type="text" placeholder="İsim" />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md="6">
                                                <FloatingLabel controlId="email" label="E-Mail Adresi" className="mb-4">
                                                    <Form.Control type="email" placeholder="name@example.com" />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md="12">
                                                <FloatingLabel controlId="phone" label="Telefon Numarası" className="mb-4">
                                                    <Form.Control type="text" placeholder="İsim" />
                                                </FloatingLabel>
                                            </Col>

                                            <Col md="12">
                                                <FloatingLabel controlId="message" label="Mesajınız">
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Mesajınız"
                                                        style={{ height: '126px' }}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <button className="primaryBtn mt-3" type="button">Gönder</button>
                                    </Form>
                                </div>
                            </Card>
                        </Col>
                        
                    </Row>
                    */}
                </Container>
            </section>
        </>
    )

}

export default Contact;